<template>
  <div style="height=100%">
    <div class="msg">
      <table width="90%" class="table">
        <caption>
          <h2>通过你邀请注册的用户</h2>
        </caption>
        <thead>
          <tr>
            <th>序号</th>
            <th>用户名</th>
          </tr>
        </thead>
        <tr
          v-for="item in list"
          :key="item.id"
          v-clipboard:copy="item.modle"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <td>
            {{ item.id }}
          </td>
          <td>{{ item.name }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    //  复制的方法
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
  async mounted() {
    let ret = await this.$api.fn_invite_user_get();
    ret = ret.data.data;
    ret.forEach((element) => {
      let id = this.list.length+1;
      this.list.push({
        name: element.yonghuming,
        id: id,
      });
    });
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 12px;
}
table thead th {
  background-color: #cce8eb;
  width: 50px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #cbf3f3;
}
.msg {
  margin-bottom: 100px;
}
.pag {
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 99;
}
</style>
